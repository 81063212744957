import React from "react";
import MainLayout from "../layouts/MainLayout";
import { Formik } from "formik";
import * as Yup from "yup";

class Contact extends React.Component {
  getInitialValues = () => {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      message: "",
    };
  };

  getValidationSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string().required("First name cannot be empty"),
      lastName: Yup.string().required("Last name cannot be empty"),
      email: Yup.string()
        .email("Please write a valid email")
        .required("Email cannot be empty"),
      message: Yup.string().required("Message cannot be empty"),
    });
  };

  sendMessage = (values) => {
    fetch("https://lutianshowers.com/admin/api/sendmessage", {
      // content-type header should not be specified!
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        alert("Your message was successfully sent");
      })
      .catch((error) => console.log(error));
  };

  render() {
    return (
      <MainLayout>
        <div className="product-grid">
          <div className="flex contact-wrapper">
            <div>
              <h2>Contact</h2>
              <p>Email: lumi@email.com</p>
              <p>Phone: +38344573054</p>
              <p>Address: Prishtine, Kosovo</p>
              <p>Web: lutianshowers.com</p>
            </div>
            <div>
              <Formik
                enableReinitialize={true}
                initialValues={this.getInitialValues()}
                onSubmit={this.sendMessage}
                validationSchema={this.getValidationSchema()}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isValid,
                  } = props;
                  return (
                    <form>
                      <label>
                        First Name*
                        <input
                          onBlur={handleBlur}
                          type="text"
                          placeholder="Enter first name"
                          onChange={handleChange}
                          id="firstName"
                          value={values.firstName}
                          name="firstName"
                        />
                        {errors.firstName && touched.firstName && (
                          <span className="red-error">{errors.firstName}</span>
                        )}
                      </label>
                      <label>
                        Last Name*
                        <input
                          onBlur={handleBlur}
                          type="text"
                          placeholder="Enter last name"
                          onChange={handleChange}
                          id="lastName"
                          value={values.lastName}
                          name="lastName"
                        />
                        {errors.lastName && touched.lastName && (
                          <span className="red-error">{errors.lastName}</span>
                        )}
                      </label>
                      <label>
                        Email*
                        <input
                          onBlur={handleBlur}
                          type="email"
                          placeholder="Enter email"
                          onChange={handleChange}
                          id="email"
                          value={values.email}
                          name="email"
                        />
                        {errors.email && touched.email && (
                          <span className="red-error">{errors.email}</span>
                        )}
                      </label>
                      <label>
                        Phone Number
                        <input
                          onBlur={handleBlur}
                          type="text"
                          placeholder="Enter phone number"
                          onChange={handleChange}
                          id="phone"
                          value={values.phone}
                          name="phone"
                        />
                        {errors.phone && touched.phone && (
                          <span className="red-error">{errors.phone}</span>
                        )}
                      </label>
                      <label className="full-width">
                        Company
                        <input
                          onBlur={handleBlur}
                          type="text"
                          placeholder="Enter company"
                          onChange={handleChange}
                          id="company"
                          value={values.company}
                          name="company"
                        />
                        {errors.company && touched.company && (
                          <span className="red-error">{errors.company}</span>
                        )}
                      </label>
                      <label className="full-width">
                        Message*
                        <textarea
                          onBlur={handleBlur}
                          placeholder="Enter message"
                          onChange={handleChange}
                          id="message"
                          value={values.message}
                          name="message"
                        />
                        {errors.message && touched.message && (
                          <span className="red-error">{errors.message}</span>
                        )}
                      </label>
                      <button
                        disabled={!isValid}
                        onClick={handleSubmit}
                        type="button"
                      >
                        Send Message
                      </button>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default Contact;
