import React from "react";
import PrivateProducts from "./Products/PrivateProducts";
import PublicProducts from "./Products/PublicProducts";
import Loader from "../components/Loader";
import MainLayout from "../layouts/MainLayout";

class Products extends React.Component {
  state = {
    isLoggedIn: false,
    isLoading: true,
  };

  componentDidMount = () => {
    const expiration = Date.parse(localStorage.tokenexpiry);
    const currentTime = Date.now();
    if (localStorage.usertoken && expiration > currentTime) {
      this.setState({
        isLoading: false,
        isLoggedIn: true,
      });
    } else {
      this.setState({
        isLoading: false,
        isLoggedIn: false,
      });
    }
  };

  render() {
    return (
      <MainLayout>
        {this.state.isLoading && <Loader />}
        {!this.state.isLoading && (
          <>
            {this.state.isLoggedIn && <PrivateProducts />}
            {!this.state.isLoggedIn && <PublicProducts />}
          </>
        )}
      </MainLayout>
    );
  }
}

export default Products;
