import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";

class CustomSlider extends React.Component {
  render() {
    const content = [
      {
        title: "Messina Dark Grey",
        description:
          "Banjo e realizuar për klientin nga Lutian Home me pllakën Messina Dark Grey 80x80!",
        // image:
        //   "https://scontent.fprx2-1.fna.fbcdn.net/v/t1.0-9/118156931_1151494181898750_7844605289958250647_o.jpg?_nc_cat=100&ccb=2&_nc_sid=730e14&_nc_ohc=scu9PLxsywgAX_FYi3Q&_nc_ht=scontent.fprx2-1.fna&oh=3b3efa4d8a4097a83e320a6c5f793bdc&oe=5FBC5E57",
        image:
          "https://static.onecms.io/wp-content/uploads/sites/23/2019/01/25/bathroom-design-ideas-cleaner-bathroom-marble-tile.jpg",
      },
      {
        title: "White Bathroom",
        description:
          "Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec sed odio dui.",
        // image:
        //   "https://scontent.fprx2-1.fna.fbcdn.net/v/t1.0-9/119955159_1180566315658203_1092423219348186066_o.jpg?_nc_cat=106&ccb=2&_nc_sid=730e14&_nc_ohc=fhX6KHC3PkcAX_Liays&_nc_ht=scontent.fprx2-1.fna&oh=b9f887216f70cdd38dadfb029daca5c8&oe=5FB951E7",
        image:
          "https://static.onecms.io/wp-content/uploads/sites/23/2019/01/25/bathroom-design-ideas-cleaner-bathroom-marble-tile.jpg",
      },
      {
        title: "AR Bathroom",
        description:
          "Banjo e realizuar për klientin duke perdorur teknolgjine e fundit AR e mundesuar nga Lutian Home.",
        // image:
        //   "https://scontent.fprx2-1.fna.fbcdn.net/v/t1.0-9/122136752_1206054669776034_3083385767123063016_o.jpg?_nc_cat=109&ccb=2&_nc_sid=730e14&_nc_ohc=x1EVKsftS5sAX_ErcRS&_nc_oc=AQngrENw7ykDAycvpDzOPrnN6fAgy3hFOYV2uKqssgvhcXP2bBcOoD-JckXov2-p4XU&_nc_ht=scontent.fprx2-1.fna&oh=d67803dd049ac3df96a714b6680296a9&oe=5FBA9C86",
        image:
          "https://static.onecms.io/wp-content/uploads/sites/23/2019/01/25/bathroom-design-ideas-cleaner-bathroom-marble-tile.jpg",
      },
    ];
    return (
      <Slider autoplay={1000} className="slider-wrapper" infinite={true}>
        {content.map((item, index) => (
          <div
            key={index}
            className="slider-content"
            style={{
              background: `url('${item.image}') no-repeat center center`,
            }}
          >
            <div className="inner">
              <h1>{item.title}</h1>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}

export default CustomSlider;
