import React from "react";
import { Nav, NavLink } from "reactstrap";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { url } from "../const/url";
import Fade from "react-reveal/Fade";

class Header extends React.Component {
  state = {
    isLoggedIn: false,
    currentUser: "",
    isMenuOpen: false,
  };

  componentDidMount = () => {
    axios
      .get("https://lutianshowers.com/admin/api/getCurrentUser", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        const currentUser = res.data;
        this.setState({
          currentUser,
          isLoggedIn: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  logOut = () => {
    axios
      .get("https://lutianshowers.com/admin/api/company/logout", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        localStorage.removeItem("usertoken");
        window.location.replace("/");
        this.setState({
          isLoggedIn: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toggleMenu = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    });
  };

  render() {
    return (
      <>
        <div
          className={`nav-container header${
            this.state.isMenuOpen ? " open-menu-nav" : ""
          }`}
        >
          <Link to="/">
            <h3>Lutian</h3>
          </Link>
          <Nav>
            <NavLink>
              <i className="fa fa-instagram" />
            </NavLink>
            <NavLink>
              <i className="fa fa-facebook" />
            </NavLink>
            <NavLink>
              <i className="fa fa-whatsapp" />
            </NavLink>
          </Nav>
        </div>
        <div className="nav-container header header-right">
          <NavLink>
            <div className="menu-icon-wrapper">
              <p>Menu</p>
              {this.state.isMenuOpen ? (
                <img
                  src={`${url}close-icon.svg`}
                  onClick={this.toggleMenu}
                  alt="icon"
                  className="cursor-pointer"
                />
              ) : (
                <img
                  className="open-menu-img cursor-pointer"
                  src={`${url}menu-icon.svg`}
                  onClick={this.toggleMenu}
                  alt="icon"
                />
              )}
            </div>
          </NavLink>
          <Nav>
            <Link className="vertical-text" to="/contact">
              Contact
            </Link>
          </Nav>
        </div>
        {this.state.isMenuOpen && (
          <div className="menu">
            <Fade>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>About</li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </Fade>
            <div className="menu-logo">
              <h1>Lutian</h1>
            </div>
            <div className="menu-login">
              {!this.state.isLoggedIn && (
                <Link to="login">
                  <h3>Login</h3>
                </Link>
              )}
              {this.state.isLoggedIn && <h3 onClick={this.logOut}>Logout</h3>}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(Header);
