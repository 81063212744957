import React from "react";
import { Row, Col, Input, Container } from "reactstrap";
import axios from "axios";
import { url, productImg, varImg } from "../../const/url";
import Loader from "../../components/Loader";
import Fade from "react-reveal/Fade";
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import CustomSlider from "../../components/CustomSlider";
import Tooltip from "react-simple-tooltip";
import fileDownload from "js-file-download";

class PrivateProducts extends React.Component {
  state = {
    products: [],
    selectedProducts: [],
    isLoading: true,
    options: [],
    inputWidth: "",
    inputLength: "",
    inputHeight: "",
    shops: [],
    searchedState: "",
    selectedStateId: null,
    hoveredSubVar: null,
    isHoverSubVarDisplayed: false,
  };

  componentDidMount = () => {
    this.getProducts();
    this.getShops();
    this.getAlonso()
  };

  timer = null;

  getAlonso = () => {
    axios
      .get("http://ergast.com/api/f1/drivers/alonso.json", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("res:", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getProducts = () => {
    axios
      .get("https://lutianshowers.com/admin/api/public/products", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const products = res.data;
        products.forEach(function (element) {
          element.NewWidth = +element.MinWidth;
          element.NewLength = +element.MinLength;
          element.NewHeight = +element.MinHeight;
          element.sliderImages = element.products_photo.map((item) => ({
            original: `${productImg}Medium-${item.filename}`,
            thumbnail: `${productImg}Small-${item.filename}`,
          }));
        });

        console.log("products:", products);
        this.getProductOptions(products);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getProductOptions = (products) => {
    products.forEach((item) => {
      const productId = item.id;
      axios
        .get(
          `https://lutianshowers.com/admin/api/public/products/options/${productId}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          const options = res.data;
          item.variation = options;
          this.setState(
            {
              products,
              isLoading: false,
            },
            () => {
              console.log("123123", this.state.products);
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  onInputChange = (e) => {
    if (["NewWidth", "NewLength", "NewHeight"].includes(e.target.className)) {
      let products = [...this.state.products];
      products[e.target.dataset.id][e.target.className] = +e.target.value;
      this.setState({
        products,
      });
    }
  };

  showProductInfo = (product) => {
    const selectedProducts = [...this.state.selectedProducts];
    selectedProducts.push(product);
    this.setState({
      selectedProducts,
    });
  };

  removeSelectedProducts = (product) => {
    let selectedProducts = [...this.state.selectedProducts];
    selectedProducts = selectedProducts.filter(
      (item) => item.id !== product.id
    );
    this.setState({
      selectedProducts,
    });
  };

  getShops = () => {
    axios
      .get("https://lutianshowers.com/admin/api/public/shops", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const shops = res.data;
        this.setState({
          shops,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setSelectedAddress = (e) => {
    const selectedAddress = this.state.companies.find(
      (add) => parseInt(e.target.value, 10) === add.id
    );
    this.setState({
      selectedAddress,
    });
  };

  showSearchCountry = (selectedStateId) => {
    if (selectedStateId === this.state.selectedStateId) {
      this.setState({
        isShowSearchCountryOpen: !this.state.isShowSearchCountryOpen,
        selectedStateId: null,
        searchedState: "",
      });
      return;
    }
    this.setState({
      isShowSearchCountryOpen: true,
      selectedStateId,
      searchedState: "",
    });
  };

  keyPress = (e) => {
    if (e.keyCode === 27) {
      this.showSearchCountry();
    }
  };

  showSubVarImgPreview = (hoveredSubVar, productId) => {
    clearTimeout(this.timer);
    this.setState({
      hoveredSubVar: {
        ...hoveredSubVar,
        subVarCondition: hoveredSubVar.option + hoveredSubVar.variation_id,
        productId,
      },
      isHoverSubVarDisplayed: true,
    });
  };

  removeSubVarImgPreview = () => {
    this.timer = setTimeout(() => {
      this.setState({
        hoveredSubVar: null,
        isHoverSubVarDisplayed: false,
      });
    }, 1000);
    return this.timer;
  };

  handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  render() {
    let filteredShops = [];
    if (this.state.searchedState !== "") {
      filteredShops = this.state.shops.filter((item) => {
        const shopState = item.shop_country.toLowerCase();
        const shopCity = item.shop_city.toLowerCase();
        const filter = this.state.searchedState.toLowerCase();
        return shopState.startsWith(filter) || shopCity.startsWith(filter);
      });
    }

    return (
      <>
        <div className="full-width public-products-wrapper">
          {this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <>
              <div className="full-width">
                <CustomSlider />
              </div>
              <div className="full-width">
                {this.state.products.length > 0 &&
                  this.state.products.map((item, index) => {
                    let featuredImage = item.products_photo.find(
                      (img) => img.featured === "1"
                    );
                    if (!featuredImage) {
                      featuredImage = item.products_photo[0];
                    }
                    let widthId = `width-${index}`;
                    let lengthId = `length-${index}`;
                    let heightId = `height-${index}`;
                    return (
                      <Col
                        lg="12"
                        md="12"
                        sm="12"
                        xs="12"
                        className={`product-grid${
                          this.state.selectedProducts.find(
                            (prod) => prod.id === item.id
                          )
                            ? " extended"
                            : ""
                        }`}
                      >
                        <div className="product-grid-animation" />
                        <Fade>
                          <Container className="position-relative">
                            <Row>
                              <Col
                                lg="5"
                                xs="12"
                                className="product-grid-info position-relative"
                              >
                                <div>
                                  <h4>{item.product_name}</h4>
                                  <p>{item.product_description}</p>
                                  <div
                                    className="position-relative"
                                    onMouseLeave={this.removeSubVarImgPreview}
                                  >
                                    {item.variation &&
                                      item.variation.length > 0 &&
                                      item.variation.map((variation) => (
                                        <div className="product-grid-options public-product-grid-option">
                                          <p>{variation.name}</p>
                                          {variation.level1.map((subVar) => (
                                            <div
                                              className="position-relative"
                                              onMouseOver={() => {
                                                this.showSubVarImgPreview(
                                                  subVar,
                                                  item.id
                                                );
                                              }}
                                            >
                                              <p>{subVar.option}</p>
                                            </div>
                                          ))}
                                        </div>
                                      ))}
                                    {this.state.isHoverSubVarDisplayed &&
                                      item.id ===
                                        this.state.hoveredSubVar.productId && (
                                        <div
                                          className="subvar-img-preview"
                                          onMouseOver={() =>
                                            clearTimeout(this.timer)
                                          }
                                        >
                                          <h3>{item.product_name}</h3>
                                          <p>
                                            {this.state.hoveredSubVar.option}
                                          </p>
                                          {this.state.hoveredSubVar.image && (
                                            <img
                                              src={`${varImg}${this.state.hoveredSubVar.image}`}
                                              alt="img-preview"
                                            />
                                          )}
                                        </div>
                                      )}
                                  </div>
                                  <div className="product-grid-inputs">
                                    <Tooltip
                                      content={`${item.MinWidth}cm - ${item.MaxWidth}cm`}
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      <div className="relative">
                                        <input
                                          type="number"
                                          name={widthId}
                                          data-id={index}
                                          id={widthId}
                                          className="NewWidth"
                                          value={item.NewWidth}
                                          onChange={this.onInputChange}
                                        />
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      content={`${item.MinLength}cm - ${item.MaxLength}cm`}
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      <div className="relative">
                                        <input
                                          type="number"
                                          name={lengthId}
                                          data-id={index}
                                          id={lengthId}
                                          className="NewLength"
                                          value={item.NewLength}
                                          onChange={this.onInputChange}
                                          disabled={
                                            +item.MinLength < 1 &&
                                            +item.MaxLength < 1
                                          }
                                        />
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      content={`${item.MinHeight}cm - ${item.MaxHeight}cm`}
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      <div className="relative">
                                        <input
                                          type="number"
                                          name={heightId}
                                          data-id={index}
                                          id={heightId}
                                          className="NewHeight"
                                          value={item.NewHeight}
                                          onChange={this.onInputChange}
                                        />
                                      </div>
                                    </Tooltip>
                                    {(item.NewWidth < +item.MinWidth ||
                                      item.NewWidth > +item.MaxWidth ||
                                      item.NewLength < +item.MinLength ||
                                      item.NewLength > +item.MaxLength ||
                                      item.NewHeight < +item.MinHeight ||
                                      item.NewHeight > +item.MaxHeight) && (
                                      <Tooltip
                                        content="Your order will need confirmation if you choose dimensions outside of the range!"
                                        style={{ whiteSpace: "nowrap" }}
                                        className="warning-order"
                                      >
                                        <i
                                          className="fa fa-exclamation-triangle"
                                          aria-hidden="true"
                                        />
                                      </Tooltip>
                                    )}
                                  </div>
                                  {this.state.selectedProducts.find(
                                    (prod) => prod.id === item.id
                                  ) && (
                                    <>
                                      <div className="full-width nearby-shop-wrapper flex">
                                        <div className="full-width position-relative">
                                          <div
                                            className={`search-country flex full-width${
                                              this.state
                                                .isShowSearchCountryOpen &&
                                              this.state.selectedStateId ===
                                                item.id
                                                ? " open"
                                                : ""
                                            }`}
                                          >
                                            <Input
                                              type="search"
                                              name="searchedState"
                                              value={this.state.searchedState}
                                              id="searchedState"
                                              onChange={this.onChange}
                                              onKeyDown={this.keyPress}
                                              disabled={
                                                this.state.selectedStateId !==
                                                item.id
                                              }
                                            />

                                            <img
                                              src={`${url}search.png`}
                                              alt="search"
                                              onClick={() =>
                                                this.showSearchCountry(item.id)
                                              }
                                            />
                                            <p>Search for Country</p>
                                          </div>
                                          {filteredShops.length > 0 &&
                                            this.state.selectedStateId ===
                                              item.id && (
                                              <Col
                                                className="filtered-shops"
                                                lg="12"
                                                md="12"
                                                sm="12"
                                                xs="12"
                                              >
                                                <div className="full-width">
                                                  {filteredShops.map((item) => (
                                                    <Col
                                                      className="nearby-shop-item"
                                                      lg="6"
                                                      md="6"
                                                      sm="6"
                                                      xs="12"
                                                    >
                                                      <div>
                                                        <h6>
                                                          {item.shop_name}
                                                        </h6>
                                                        <p>
                                                          {item.shop_street},
                                                        </p>
                                                        <p>
                                                          {item.shop_city}{" "}
                                                          {item.shop_zip},{" "}
                                                          {item.shop_country}
                                                        </p>
                                                      </div>
                                                    </Col>
                                                  ))}
                                                </div>
                                              </Col>
                                            )}
                                        </div>
                                      </div>
                                      <button
                                        onClick={() => {
                                          this.handleDownload(
                                            "https://lutianshowers.com/public/images/pdf/test-download.pdf",
                                            "test-download.pdf"
                                          );
                                        }}
                                      >
                                        Download PDF
                                      </button>
                                    </>
                                  )}

                                  <div className="show-more-product flex">
                                    {this.state.selectedProducts.find(
                                      (prod) => prod.id === item.id
                                    ) && (
                                      <div
                                        className="view-less-details flex"
                                        style={{ marginTop: "0" }}
                                      >
                                        <i className="material-icons">
                                          arrow_right_alt
                                        </i>
                                        <span
                                          className="view-less-details-span"
                                          onClick={() =>
                                            this.removeSelectedProducts(item)
                                          }
                                        >
                                          View Less
                                        </span>
                                      </div>
                                    )}
                                    <div className="view-details flex">
                                      <span
                                        onClick={() =>
                                          this.showProductInfo(item)
                                        }
                                      >
                                        View Details
                                      </span>
                                      <i className="material-icons">
                                        arrow_right_alt
                                      </i>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col lg="7" xs="12" className="product-grid-img">
                                {this.state.selectedProducts.find(
                                  (prod) => prod.id === item.id
                                ) ? (
                                  <ImageGallery
                                    items={item.sliderImages}
                                    thumbnailPosition={
                                      index % 2 === 0 ? "right" : "left"
                                    }
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    showNav={false}
                                  />
                                ) : (
                                  <div className="product-grid-img-section">
                                    {item.products_photo.length > 0 && (
                                      <img
                                        src={`${productImg}Medium-${featuredImage.filename}`}
                                        alt="product_image"
                                      />
                                    )}
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Container>
                        </Fade>
                      </Col>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default PrivateProducts;
