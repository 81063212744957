import React from "react";
import { login } from "./UserFunctions.js";
import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";
import Modal from "../components/Modal";

class Login extends React.Component {
  componentDidMount = () => {
    document.addEventListener("keydown", this.handleKeyDown);
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.handleKeyDown);
  };

  handleKeyDown = (e) => {
    const code = e.keyCode || e.which;
    const enterKey = 13;
    if (code === enterKey) {
      this.onSubmit();
    }
  };

  state = {
    email: "",
    password: "",
    errors: null,
    isRegisterModalVisible: false,
  };

  onChange = (e) => {
    const errors = { ...this.state.errors };
    delete errors[e.target.name];

    if (errors.credentials) {
      delete errors.credentials;
    }

    this.setState({
      [e.target.name]: e.target.value,
      errors,
    });
  };

  onSubmit = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }

    const errors = { ...this.state.errors };

    if (!this.state.email) {
      errors.email = "Email cannot be left empty";
    } else {
      delete errors.email;
    }
    if (!this.state.password) {
      errors.password = "Password cannot be left empty";
    } else {
      delete errors.password;
    }

    if (errors) {
      this.setState({
        errors,
      });
    }

    if (Object.keys(errors).length === 0) {
      const user = {
        email: this.state.email,
        password: this.state.password,
      };

      login(user).then((res) => {
        if (res) {
          window.location.replace("/");
        } else {
          errors.credentials =
            "There was an error processing your request. Please re-check your credentials!";
          this.setState({
            errors,
          });
        }
      });
    }
  };

  toggleRegisterModal = () => {
    this.setState({
      isRegisterModalVisible: !this.state.isRegisterModalVisible,
    });
  };

  render() {
    return (
      <MainLayout>
        <div className="dashboard-content container login-content">
          <form>
            {this.state.errors && this.state.errors.credentials && (
              <label>
                <span style={{ color: "red" }}>
                  {this.state.errors.credentials}
                </span>
              </label>
            )}
            <label>
              E-mail
              <input
                type="email"
                name="email"
                onChange={this.onChange}
                value={this.state.email}
              />
              {this.state.errors && this.state.errors.email && (
                <span style={{ color: "red" }}>{this.state.errors.email}</span>
              )}
            </label>
            <label>
              Password
              <input
                type="password"
                name="password"
                onChange={this.onChange}
                value={this.state.password}
              />
              {this.state.errors && this.state.errors.password && (
                <span style={{ color: "red" }}>
                  {this.state.errors.password}
                </span>
              )}
            </label>
            <div className="flex justify-space-between">
              <h6 onClick={this.onSubmit}>Login</h6>
              <h6 onClick={this.toggleRegisterModal}>Register</h6>
              <Link to="/" className="flex">
                <h6>
                  <i className="material-icons">keyboard_backspace</i>Go Back
                </h6>
              </Link>
            </div>
          </form>
          <Modal
            className="additonal-modal added-successfully-modal notifications-modal"
            display={this.state.isRegisterModalVisible}
            title="Register"
            onClose={this.toggleRegisterModal}
          >
            Lutian Showers only accepts registrations by contract. <br />
            If interested, please contact the site administrator by sending an
            email at{" "}
            <u>
              <a href="mailto: info@lutianshowers.com">
                info@lutianshowers.com
              </a>
            </u>
          </Modal>
        </div>
      </MainLayout>
    );
  }
}

export default Login;
