import React from "react";
import { Row, Col, Container } from "reactstrap";
import axios from "axios";
import { productImg, url, varImg } from "../../const/url";
import ShoppingCart from "../../components/ShoppingCart";
import CustomSlider from "../../components/CustomSlider";
import Checkout from "../../components/Checkout";
import OrderStatus from "../../components/OrderStatus";
import Loader from "../../components/Loader";
import Fade from "react-reveal/Fade";
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import moment from "moment-timezone";
import Modal from "../../components/Modal";
import Dropzone from "react-dropzone";
import Tooltip from "react-simple-tooltip";
import fileDownload from "js-file-download";

class PrivateProducts extends React.Component {
  state = {
    products: [],
    currentUser: null,
    selectedProducts: [],
    isLoading: true,
    variations: [],
    selectedSubVar: 0,
    isShoppingCartVisible: false,
    cart: [],
    isNotificationsViewVisible: false,
    isCheckoutPageVisible: false,
    isOrderStatusPageVisible: false,
    orderConfirmationStatus: false,
    currentProduct: null,
    selectedProductIndex: null,
    currentAddToCartItem: null,
    addedToCartConfirm: false,
    notifications: [],
    selectedNotification: null,
    hoveredSubVar: null,
    isHoverSubVarDisplayed: false,
    productImages: [],
  };

  timer = null;

  componentDidMount = () => {
    axios
      .get("https://lutianshowers.com/admin/api/getCurrentUser", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        const currentUser = res.data;
        this.setState(
          {
            currentUser,
          },
          () => {
            this.getYourProducts(this.state.currentUser.id);

            if (
              localStorage.getItem("shoppingCart") &&
              localStorage.getItem("currentUserId") ===
                this.state.currentUser.id.toString()
            ) {
              this.setState({
                cart: JSON.parse(localStorage.getItem("shoppingCart")),
              });
            }
            this.getNotifications();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setReadNotification = (notification_id) => {
    let formData = new FormData();
    formData.append("notification_id", notification_id);
    formData.append("user_id", this.state.currentUser.id);

    fetch("https://lutianshowers.com/admin/api/notification/status", {
      // content-type header should not be specified!
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
      },
      body: formData,
    })
      .then((response) => {
        console.log("Report sent", response);
        this.getNotifications();
        this.setSelectedNotification(notification_id);
      })
      .catch((error) => console.log(error));
  };

  getNotifications = () => {
    const id = this.state.currentUser.id;
    axios
      .get(`https://lutianshowers.com/admin/api/notifications/user/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        const notifications = res.data;
        console.log("-----", notifications);
        this.setState({
          notifications,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getYourProducts = (id) => {
    axios
      .get(`https://lutianshowers.com/admin/api/products/user/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        let products = res.data.products;
        products.forEach(function (element) {
          element.NewWidth = +element.MinWidth;
          element.NewLength = +element.MinLength;
          element.NewHeight = +element.MinHeight;
          element.productPrice = +element.pivot.product_price;
          element.variations_price = 0;
          element.chosenVariations = [];
          element.addedPercentage = 1;
          element.productTotalPrice = +element.pivot.product_price;
          element.editedPriceForCustomer = element.productTotalPrice;
          element.productOrderMessage = "";
          element.productOrderPhotos = [];
          element.products_photo = [];
        });

        this.getProductOptions(products);
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onDrop = (acceptedFiles) => {
    const errors = { ...this.state.errors };
    delete errors.img;

    const fileObj = acceptedFiles.map(async (file) => ({
      imgContent: await this.getBase64(file),
      fileName: file.name,
      contentType: file.type,
      size: file.size,
    }));
    let products = [...this.state.products];
    Promise.all(fileObj).then(
      (result) =>
        (products[this.state.currentProduct].productOrderPhotos = result)
    );

    this.setState({
      products,
    });
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_) => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });
  };

  toggleMessageModal = (index) => {
    this.setState(
      {
        selectedProductIndex: index,
        isCartConfirmationVisible: false,
      },
      () => {
        this.setState({
          isProductModalOpen: true,
          currentProduct: index,
        });
      }
    );
  };

  closeProductModal = () => {
    this.setState({
      isProductModalOpen: false,
    });
  };

  getProductImages = () => {
    axios
      .get("https://lutianshowers.com/admin/api/products", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        const productImages = res.data;
        const products = [...this.state.products];
        products.forEach((item) => {
          item.products_photo = productImages.find(
            (x) => x.id === item.id
          )?.products_photo;
          item.sliderImages = item.products_photo.map((item) => ({
            original: `${productImg}Medium-${item.filename}`,
            thumbnail: `${productImg}Small-${item.filename}`,
          }));
        });
        this.setState({
          products,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getProductOptions = (products) => {
    products.forEach((item) => {
      const productId = item.id;
      axios
        .get(
          `https://lutianshowers.com/admin/api/public/products/options/${productId}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          const options = res.data;
          item.variation = options;
          let variationsPrice = +item.variations_price;
          item.variation.forEach((innerVar) => {
            let defaultVar = innerVar.level1.find((level) => level.default);
            if (!defaultVar) {
              defaultVar =
                innerVar.level1.length > 0 ? innerVar.level1[0] : null;
            }
            item.chosenVariations.push({
              id: innerVar.option_id,
              type: innerVar.name,
              value: defaultVar?.option,
              image: defaultVar?.image,
              price: +defaultVar?.price,
            });
            variationsPrice = variationsPrice + +defaultVar?.price;
            item.variations_price = +variationsPrice.toFixed(2);
          });
          console.log("products:", products);

          this.setState(
            {
              products,
            },
            () => {
              this.getProductImages();
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  toggleAddToCart = (item, index) => {
    this.setState({
      isCartConfirmationVisible: true,
      currentAddToCartItem: item,
      currentProduct: index,
    });
  };

  closeAddToCart = () => {
    this.setState({
      isCartConfirmationVisible: false,
    });
  };

  showProductInfo = (product) => {
    const selectedProducts = [...this.state.selectedProducts];
    selectedProducts.push(product);
    this.setState({
      selectedProducts,
    });
  };

  removeSelectedProducts = (product) => {
    let selectedProducts = [...this.state.selectedProducts];
    selectedProducts = selectedProducts.filter(
      (item) => item.id !== product.id
    );
    this.setState({
      selectedProducts,
    });
  };

  setSelectedOption = (variation) => {
    this.setState({
      selectedVar: variation,
    });
  };

  setSelectedSubOption = (subVar) => {
    this.setState({
      selectedSubVar: subVar.price,
    });
  };

  toggleShoppingCart = () => {
    this.setState({
      isShoppingCartVisible: !this.state.isShoppingCartVisible,
    });
  };

  toggleCheckoutPage = () => {
    this.setState({
      isShoppingCartVisible: false,
      isCheckoutPageVisible: !this.state.isCheckoutPageVisible,
    });
  };

  toggleOrderStatusPage = () => {
    this.setState({
      isShoppingCartVisible: false,
      isCheckoutPageVisible: false,
      isOrderStatusPageVisible: !this.state.isOrderStatusPageVisible,
    });
  };

  toggleNotifications = () => {
    this.setState({
      isNotificationsViewVisible: !this.state.isNotificationsViewVisible,
    });
  };

  onChange = (e) => {
    if (
      ["NewWidth", "NewLength", "NewHeight", "productOrderMessage"].includes(
        e.target.className
      )
    ) {
      let products = [...this.state.products];
      if (["productOrderMessage"].includes(e.target.className)) {
        products[e.target.dataset.id][e.target.className] = e.target.value;
      } else {
        products[e.target.dataset.id][e.target.className] = +e.target.value;
      }
      let defaultWidthDifference = 0;
      if (
        products[e.target.dataset.id].NewWidth >=
        +products[e.target.dataset.id].MinWidth
      ) {
        defaultWidthDifference =
          products[e.target.dataset.id].NewWidth -
          +products[e.target.dataset.id].MinWidth;
      }
      let defaultLengthDifference = 0;
      if (
        products[e.target.dataset.id].NewLength >=
        +products[e.target.dataset.id].MinLength
      ) {
        defaultLengthDifference =
          products[e.target.dataset.id].NewLength -
          +products[e.target.dataset.id].MinLength;
      }
      let defaultHeightDifference = 0;
      if (
        products[e.target.dataset.id].NewHeight >=
        +products[e.target.dataset.id].MinHeight
      ) {
        defaultHeightDifference =
          products[e.target.dataset.id].NewHeight -
          +products[e.target.dataset.id].MinHeight;
      }
      const widthLengthMax = Math.max(
        defaultWidthDifference,
        defaultLengthDifference
      );

      const addedPercentage =
        (widthLengthMax + defaultHeightDifference) / 100 + 1;

      products[e.target.dataset.id].addedPercentage = +addedPercentage;
      this.setState({
        products,
      });
    }
  };

  setCartConfirmModal = () => {
    this.setState({
      addedToCartConfirm: true,
    });
    setTimeout(
      function () {
        this.setState({ addedToCartConfirm: false });
      }.bind(this),
      1500
    );
  };

  addToCart = (item) => {
    let { cart } = this.state;
    let productExistsInCart;
    item.productTotalPrice =
      (+item.productPrice + +item.variations_price) * item.addedPercentage;
    item.productTotalPrice = +item.productTotalPrice.toFixed(2);
    item.editedPriceForCustomer = item.productTotalPrice;
    if (item.chosenVariations.length === 0) {
      productExistsInCart = cart.findIndex(
        (cartEl) =>
          cartEl.id === item.id &&
          cartEl.NewWidth === item.NewWidth &&
          cartEl.NewLength === item.NewLength &&
          cartEl.NewHeight === item.NewHeight &&
          cartEl.productPrice === item.productPrice &&
          cartEl.addedPercentage === item.addedPercentage &&
          cartEl.chosenVariations.length === 0 &&
          !cartEl.productOrderMessage &&
          !item.productOrderMessage &&
          cartEl.productOrderPhotos.length === 0 &&
          item.productOrderPhotos.length === 0
      );
    }
    if (item.chosenVariations.length > 0) {
      productExistsInCart = cart.findIndex(
        (cartEl) =>
          cartEl.id === item.id &&
          cartEl.NewWidth === item.NewWidth &&
          cartEl.NewLength === item.NewLength &&
          cartEl.NewHeight === item.NewHeight &&
          cartEl.productPrice === item.productPrice &&
          cartEl.addedPercentage === item.addedPercentage &&
          !cartEl.productOrderMessage &&
          !item.productOrderMessage &&
          cartEl.productOrderPhotos.length === 0 &&
          item.productOrderPhotos.length === 0 &&
          cartEl.chosenVariations.every((element) =>
            item.chosenVariations.find(
              (itemVariation) =>
                element.type === itemVariation.type &&
                element.value === itemVariation.value &&
                element.id === itemVariation.id
            )
          ) &&
          cartEl.chosenVariations.length > 0 &&
          cartEl.chosenVariations.length === item.chosenVariations.length
      );
    }

    if (cart.length === 0) {
      item.cartCounter = 1;
    } else {
      cart.forEach((item) => {
        if (!item.cartCounter || item.cartCounter === 1) {
          item.cartCounter = 1;
        }
      });
    }

    if (productExistsInCart === -1) {
      cart.push({ ...item });
      cart.forEach((item, index) => {
        item.cartId = index;
        if (!item.cartCounter) {
          item.cartCounter = 1;
        }
      });
    }

    if (productExistsInCart !== -1) {
      cart[productExistsInCart].cartCounter++;
    }

    this.setState(
      {
        cart,
        isCartConfirmationVisible: false,
        isProductModalOpen: false,
      },
      () => {
        localStorage.setItem("shoppingCart", JSON.stringify(this.state.cart));
        localStorage.setItem("currentUserId", this.state.currentUser.id);
        this.clearAdditionalRequestFromProduct();
        this.setCartConfirmModal();
      }
    );
  };

  clearAdditionalRequestFromProduct = () => {
    let products = [...this.state.products];
    products.forEach((item) => {
      item.productOrderMessage = "";
      item.productOrderPhotos = [];
    });
    this.setState({
      products,
    });
  };

  onCountIncrement = (item) => {
    const { cart } = this.state;
    let productExistsInCart;
    if (item.chosenVariations.length === 0) {
      productExistsInCart = cart.findIndex(
        (cartEl) =>
          cartEl.id === item.id &&
          cartEl.NewWidth === item.NewWidth &&
          cartEl.NewLength === item.NewLength &&
          cartEl.NewHeight === item.NewHeight &&
          cartEl.productPrice === item.productPrice &&
          cartEl.addedPercentage === item.addedPercentage &&
          cartEl.productOrderMessage === item.productOrderMessage &&
          JSON.stringify(cartEl.productOrderPhotos) ===
            JSON.stringify(item.productOrderPhotos) &&
          cartEl.chosenVariations.length === 0
      );
    }
    if (item.chosenVariations.length > 0) {
      productExistsInCart = cart.findIndex(
        (cartEl) =>
          cartEl.id === item.id &&
          cartEl.NewWidth === item.NewWidth &&
          cartEl.NewLength === item.NewLength &&
          cartEl.NewHeight === item.NewHeight &&
          cartEl.productPrice === item.productPrice &&
          cartEl.addedPercentage === item.addedPercentage &&
          cartEl.productOrderMessage === item.productOrderMessage &&
          JSON.stringify(cartEl.productOrderPhotos) ===
            JSON.stringify(item.productOrderPhotos) &&
          cartEl.chosenVariations.every((element) =>
            item.chosenVariations.find(
              (itemVariation) =>
                element.type === itemVariation.type &&
                element.value === itemVariation.value &&
                element.id === itemVariation.id
            )
          ) &&
          cartEl.chosenVariations.length > 0 &&
          cartEl.chosenVariations.length === item.chosenVariations.length
      );
    }
    if (productExistsInCart !== -1) {
      cart[productExistsInCart].cartCounter++;
    }
    this.setState(
      {
        cart,
      },
      () => {
        localStorage.setItem("shoppingCart", JSON.stringify(this.state.cart));
        localStorage.setItem("currentUserId", this.state.currentUser.id);
      }
    );
  };

  onCountDecrement = (item) => {
    const { cart } = this.state;
    let productExistsInCart;
    if (item.chosenVariations.length === 0) {
      productExistsInCart = cart.findIndex(
        (cartEl) =>
          cartEl.id === item.id &&
          cartEl.NewWidth === item.NewWidth &&
          cartEl.NewLength === item.NewLength &&
          cartEl.NewHeight === item.NewHeight &&
          cartEl.productPrice === item.productPrice &&
          cartEl.addedPercentage === item.addedPercentage &&
          cartEl.productOrderMessage === item.productOrderMessage &&
          JSON.stringify(cartEl.productOrderPhotos) ===
            JSON.stringify(item.productOrderPhotos) &&
          cartEl.chosenVariations.length === 0
      );
    }
    if (item.chosenVariations.length > 0) {
      productExistsInCart = cart.findIndex(
        (cartEl) =>
          cartEl.id === item.id &&
          cartEl.NewWidth === item.NewWidth &&
          cartEl.NewLength === item.NewLength &&
          cartEl.NewHeight === item.NewHeight &&
          cartEl.productPrice === item.productPrice &&
          cartEl.addedPercentage === item.addedPercentage &&
          cartEl.productOrderMessage === item.productOrderMessage &&
          JSON.stringify(cartEl.productOrderPhotos) ===
            JSON.stringify(item.productOrderPhotos) &&
          cartEl.chosenVariations.every((element) =>
            item.chosenVariations.find(
              (itemVariation) =>
                element.type === itemVariation.type &&
                element.value === itemVariation.value &&
                element.id === itemVariation.id
            )
          ) &&
          cartEl.chosenVariations.length > 0 &&
          cartEl.chosenVariations.length === item.chosenVariations.length
      );
    }
    if (productExistsInCart !== -1 && item.cartCounter > 1) {
      cart[productExistsInCart].cartCounter--;
    }
    this.setState(
      {
        cart,
      },
      () => {
        localStorage.setItem("shoppingCart", JSON.stringify(this.state.cart));
        localStorage.setItem("currentUserId", this.state.currentUser.id);
      }
    );
  };

  deleteItem = (cartItem) => {
    let { cart } = this.state;
    cart = cart.filter((item) => item.cartId !== cartItem.cartId);
    this.setState(
      {
        cart,
      },
      () => {
        localStorage.setItem("shoppingCart", JSON.stringify(this.state.cart));
        localStorage.setItem("currentUserId", this.state.currentUser.id);
      }
    );
  };

  addProductOrderId = () => {
    const cart = [...this.state.cart];
    cart.forEach((item, index) => {
      item.productOrderId = `${this.state.currentUser.id}${moment()
        .tz("Europe/Madrid")
        .format("YYYYMMDD")}${moment().tz("Europe/Madrid").format("HHmm")}${
        index + 1
      }`;
    });
  };

  onRadioChange = (variation, subVar, event) => {
    let { products } = this.state;
    let product = JSON.parse(JSON.stringify(products[event.target.dataset.id]));
    let variationPrice = +product.variations_price;
    if (product.chosenVariations.length === 0) {
      product.chosenVariations.push({
        id: event.target.className,
        type: variation.name,
        value: event.target.value,
        image: subVar.image,
        price: +subVar.price,
      });
      variationPrice = variationPrice + +subVar.price;
    } else {
      let variationExists = product.chosenVariations.find(
        (item) => item.id === event.target.className
      );
      if (variationExists) {
        variationPrice =
          +product.variations_price +
          parseInt(subVar.price, 10) -
          parseInt(variationExists.price, 10);
        variationExists.value = event.target.value;
        variationExists.image = subVar.image;
        variationExists.price = +subVar.price;
      } else {
        product.chosenVariations.push({
          id: event.target.className,
          value: event.target.value,
          image: subVar.image,
          price: +subVar.price,
          type: variation.name,
        });
        variationPrice = variationPrice + +subVar.price;
      }
    }

    product.variations_price = +variationPrice.toFixed(2);
    products[event.target.dataset.id] = product;
    this.setState({
      products,
    });
  };

  checkOrderConfirmationStatus = (orderConfirmationStatus) => {
    this.setState({
      orderConfirmationStatus,
    });
  };

  setSelectedNotification = (notification_id) => {
    const selectedNotification = this.state.notifications.find(
      (item) => item.notification_id === notification_id
    );
    this.setState({
      isNotificationsModalVisible: true,
      selectedNotification,
    });
  };

  showSubVarImgPreview = (hoveredSubVar, productId) => {
    clearTimeout(this.timer);
    this.setState({
      hoveredSubVar: {
        ...hoveredSubVar,
        subVarCondition: hoveredSubVar.option + hoveredSubVar.variation_id,
        productId,
      },
      isHoverSubVarDisplayed: true,
    });
  };

  removeSubVarImgPreview = () => {
    this.timer = setTimeout(() => {
      this.setState({
        hoveredSubVar: null,
        isHoverSubVarDisplayed: false,
      });
    }, 1000);
    return this.timer;
  };

  handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  onCustomerEdit = (target) => {
    const cart = [...this.state.cart];
    cart.forEach((item) => {
      if (item.cartId === parseInt(target.id, 10)) {
        item.editedPriceForCustomer = parseInt(target.value, 10);
      }
    });
    this.setState({
      cart,
    });
  };

  render() {
    return (
      <div className="full-width">
        {this.state.isLoading && <Loader />}
        {!this.state.isLoading && (
          <>
            <div className="full-width">
              <CustomSlider />
            </div>
            <div
              className="shopping-cart-icon cursor-pointer"
              onClick={this.toggleCheckoutPage}
            >
              <img src={`${url}shopping-cart.svg`} alt="icon" />
              {this.state.cart.length > 0 && (
                <div className="shopping-cart-counter">
                  <span>{this.state.cart.length}</span>
                </div>
              )}
            </div>
            <div className="notifications-icon cursor-pointer">
              <img
                src={`${url}notifications.svg`}
                alt="icon"
                onClick={this.toggleNotifications}
              />
              {!this.state.isNotificationsViewVisible &&
                this.state.notifications.filter((item) => item.unread === 1)
                  .length > 0 && (
                  <div className="notifications-counter">
                    <span>
                      {
                        this.state.notifications.filter(
                          (item) => item.unread === 1
                        ).length
                      }
                    </span>
                  </div>
                )}
              {this.state.isNotificationsViewVisible && (
                <div className="notifications-view">
                  {this.state.notifications.length > 0 &&
                    this.state.notifications.map((notif) => (
                      <div
                        onClick={() =>
                          this.setReadNotification(notif.notification_id)
                        }
                        className={notif.unread === 1 ? "active" : ""}
                      >
                        <span>{notif.message}</span>
                        <span>
                          {moment()
                            .utc(notif.dateCreated)
                            .local()
                            .format("MMMM Do YYYY, h:mm")}
                        </span>
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div className="full-width">
              {this.state.products.length > 0 &&
                this.state.products.map((item, index) => {
                  let widthId = `width-${index}`;
                  let lengthId = `length-${index}`;
                  let heightId = `height-${index}`;
                  let featuredImage =
                    item.products_photo.length > 0 &&
                    item.products_photo.find((img) => img.featured === "1");
                  if (!featuredImage) {
                    featuredImage = item.products_photo[0];
                  }
                  return (
                    <Col
                      lg="12"
                      md="12"
                      sm="12"
                      xs="12"
                      className={`product-grid${
                        this.state.selectedProducts.find(
                          (prod) => prod.id === item.id
                        )
                          ? " extended"
                          : ""
                      }`}
                    >
                      <div className="product-grid-animation" />
                      <Fade>
                        <Container className="position-relative">
                          <Row>
                            <Col lg="5" xs="12" className="product-grid-info">
                              <div>
                                <h4>{item.product_name}</h4>
                                <p>{item.product_description}</p>
                                <div
                                  className="position-relative"
                                  onMouseLeave={this.removeSubVarImgPreview}
                                >
                                  {item.variation &&
                                    item.variation.length > 0 &&
                                    item.variation.map((variation) => (
                                      <div className="product-grid-options">
                                        <p>{variation.name}</p>
                                        <ul>
                                          {variation.level1.map((subVar) => (
                                            <>
                                              <li className="position-relative">
                                                <input
                                                  type="radio"
                                                  name={`${variation.name}-${variation.option_id}`}
                                                  data-id={index}
                                                  id={`${variation.name}-${item.id}-${subVar.option}`}
                                                  className={`${variation.option_id}`}
                                                  value={subVar.option}
                                                  defaultChecked={
                                                    subVar.default
                                                  }
                                                  onChange={(e) =>
                                                    this.onRadioChange(
                                                      variation,
                                                      subVar,
                                                      e
                                                    )
                                                  }
                                                />
                                                <label
                                                  htmlFor={`${variation.name}-${item.id}-${subVar.option}`}
                                                  onMouseOver={() => {
                                                    this.showSubVarImgPreview(
                                                      subVar,
                                                      item.id
                                                    );
                                                  }}
                                                >
                                                  {subVar.option}
                                                </label>
                                              </li>
                                            </>
                                          ))}
                                        </ul>
                                      </div>
                                    ))}
                                  {this.state.isHoverSubVarDisplayed &&
                                    item.id ===
                                      this.state.hoveredSubVar.productId && (
                                      <div
                                        className="subvar-img-preview"
                                        onMouseOver={() =>
                                          clearTimeout(this.timer)
                                        }
                                      >
                                        <h3>{item.product_name}</h3>
                                        <p>{this.state.hoveredSubVar.option}</p>
                                        <img
                                          src={`${varImg}${this.state.hoveredSubVar.image}`}
                                          alt="img-preview"
                                        />
                                      </div>
                                    )}
                                </div>
                                <div className="product-grid-inputs">
                                  <Tooltip
                                    content={`${item.MinWidth}cm - ${item.MaxWidth}cm`}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <div className="relative">
                                      <input
                                        type="number"
                                        name={widthId}
                                        data-id={index}
                                        id={widthId}
                                        className="NewWidth"
                                        value={item.NewWidth}
                                        onChange={this.onChange}
                                      />
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    content={`${item.MinLength}cm - ${item.MaxLength}cm`}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <div className="relative">
                                      <input
                                        type="number"
                                        name={lengthId}
                                        data-id={index}
                                        id={lengthId}
                                        className="NewLength"
                                        value={item.NewLength}
                                        onChange={this.onChange}
                                        disabled={
                                          +item.MinLength < 1 &&
                                          +item.MaxLength < 1
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    content={`${item.MinHeight}cm - ${item.MaxHeight}cm`}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <div className="relative">
                                      <input
                                        type="number"
                                        name={heightId}
                                        data-id={index}
                                        id={heightId}
                                        className="NewHeight"
                                        value={item.NewHeight}
                                        onChange={this.onChange}
                                      />
                                    </div>
                                  </Tooltip>
                                  {(item.NewWidth < +item.MinWidth ||
                                    item.NewWidth > +item.MaxWidth ||
                                    item.NewLength < +item.MinLength ||
                                    item.NewLength > +item.MaxLength ||
                                    item.NewHeight < +item.MinHeight ||
                                    item.NewHeight > +item.MaxHeight) && (
                                    <Tooltip
                                      content="Your order will need confirmation if you choose dimensions outside of the range!"
                                      style={{ whiteSpace: "nowrap" }}
                                      className="warning-order"
                                    >
                                      <i
                                        className="fa fa-exclamation-triangle"
                                        aria-hidden="true"
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                                <div className="product-grid-price">
                                  <p>
                                    {(
                                      (+item.productPrice +
                                        +item.variations_price) *
                                      +item.addedPercentage
                                    ).toFixed(2)}
                                    &euro;
                                  </p>
                                </div>
                                <button
                                  onClick={() => {
                                    this.handleDownload(
                                      "https://lutianshowers.com/public/images/pdf/test-download.pdf",
                                      "test-download.pdf"
                                    );
                                  }}
                                >
                                  Download PDF
                                </button>
                                <div className="show-more-product">
                                  <div className="view-details flex">
                                    <span
                                      onClick={() => this.showProductInfo(item)}
                                    >
                                      View Details
                                    </span>
                                    <i className="material-icons">
                                      arrow_right_alt
                                    </i>
                                  </div>
                                  {this.state.selectedProducts.find(
                                    (prod) => prod.id === item.id
                                  ) && (
                                    <>
                                      <div className="flex">
                                        <span
                                          onClick={() =>
                                            this.toggleAddToCart(item, index)
                                          }
                                        >
                                          Add to Cart
                                        </span>
                                        <i className="material-icons">
                                          arrow_right_alt
                                        </i>
                                      </div>

                                      <div className="view-less-details flex">
                                        <i className="material-icons">
                                          arrow_right_alt
                                        </i>
                                        <span
                                          className="view-less-details-span"
                                          onClick={() =>
                                            this.removeSelectedProducts(item)
                                          }
                                        >
                                          View Less
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col lg="7" xs="12" className="product-grid-img">
                              {this.state.selectedProducts.find(
                                (prod) => prod.id === item.id
                              ) ? (
                                <ImageGallery
                                  items={item.sliderImages}
                                  thumbnailPosition={
                                    index % 2 === 0 ? "right" : "left"
                                  }
                                  showFullscreenButton={false}
                                  showPlayButton={false}
                                  showNav={false}
                                />
                              ) : (
                                <div className="product-grid-img-section">
                                  {item.products_photo.length > 0 && (
                                    <img
                                      src={`${productImg}Medium-${featuredImage.filename}`}
                                      alt="product_image"
                                    />
                                  )}
                                </div>
                              )}
                            </Col>
                          </Row>
                        </Container>
                      </Fade>
                    </Col>
                  );
                })}
            </div>
            <Modal
              display={
                this.state.isNotificationsModalVisible &&
                this.state.selectedNotification
              }
              onClose={() => {
                this.setState({
                  isNotificationsModalVisible: false,
                  selectedNotification: null,
                });
              }}
              onModalSubmit={() => {
                this.setState({
                  isNotificationsModalVisible: false,
                  selectedNotification: null,
                });
              }}
              mainModalBtnText="Close"
              title="Notification"
              className="additonal-modal added-successfully-modal notifications-modal"
            >
              {this.state.selectedNotification && (
                <>
                  <p>{this.state.selectedNotification.message}</p>
                  <p>
                    {moment()
                      .utc(this.state.selectedNotification.dateCreated)
                      .local()
                      .format("MMMM Do YYYY, h:mm")}
                  </p>
                </>
              )}
            </Modal>
            <Modal
              className="additonal-modal added-successfully-modal notifications-modal"
              display={this.state.addedToCartConfirm}
              noSubmitBtn={true}
              isConfirmationModal={true}
              title="Added to Cart"
            >
              Product has been added to cart!
            </Modal>

            <Modal
              display={this.state.isCartConfirmationVisible}
              onClose={this.closeAddToCart}
              otherSubmitBtn="Additional Request"
              title="Add to Cart"
              className="additonal-modal"
              mainModalBtnText="Add to Cart"
              onSecondModalSubmit={() =>
                this.toggleMessageModal(this.state.currentProduct)
              }
              onModalSubmit={() =>
                this.addToCart(this.state.currentAddToCartItem)
              }
            >
              <p>
                Click <strong>Additional Request</strong> if you need to add an
                additional comment or image to your order, or click{" "}
                <strong>Add to Cart</strong> if you want to Add to Cart
                directly.
              </p>
            </Modal>
            <Modal
              display={
                this.state.isProductModalOpen &&
                this.state.currentProduct === this.state.selectedProductIndex
              }
              onClose={this.closeProductModal}
              className="additional-modal"
              title="Additional requests"
              mainModalBtnText="Add to Cart"
              onModalSubmit={() =>
                this.addToCart(this.state.currentAddToCartItem)
              }
            >
              <p>
                Note: Below you can write or add images about any comment or
                request you may have regarding this product. The request will be
                attached in your order!
              </p>
              <textarea
                rows="7"
                placeholder="Please write your additional requests"
                name={`productOrderMessage-${
                  this.state.currentProduct !== null &&
                  this.state.currentProduct
                }`}
                data-id={
                  this.state.currentProduct !== null &&
                  this.state.currentProduct
                }
                id={`productOrderMessage-${
                  this.state.currentProduct !== null &&
                  this.state.currentProduct
                }`}
                className="productOrderMessage"
                value={
                  this.state.products.length > 0 &&
                  this.state.currentProduct !== null &&
                  this.state.products[this.state.currentProduct]
                    .productOrderMessage
                }
                onChange={this.onChange}
              />
              <Dropzone
                onDrop={this.onDrop}
                accept="image/png, image/jpeg, image/jpg"
                multiple
                styles={{
                  width: "300px",
                  height: "150px",
                  border: "1px solid gray",
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    className="dropzone-wrapper cursor-pointer"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    Click here to upload photos!
                  </div>
                )}
              </Dropzone>
            </Modal>

            <ShoppingCart
              display={this.state.isShoppingCartVisible}
              onClose={this.toggleShoppingCart}
              cart={this.state.cart}
              onCountDecrement={this.onCountDecrement}
              onCountIncrement={this.onCountIncrement}
              deleteItem={this.deleteItem}
              onCheckoutBtnClick={this.toggleCheckoutPage}
            />
            <Checkout
              display={this.state.isCheckoutPageVisible}
              onClose={this.toggleCheckoutPage}
              cart={this.state.cart}
              onCountDecrement={this.onCountDecrement}
              onCountIncrement={this.onCountIncrement}
              deleteItem={this.deleteItem}
              onOrderClick={this.toggleOrderStatusPage}
              logisticsPrice={this.state.currentUser.logistics_price}
              checkOrderConfirmationStatus={this.checkOrderConfirmationStatus}
              toggleOrderStatusPage={this.toggleOrderStatusPage}
              userId={this.state.currentUser.id}
              onAddProductOrderId={this.addProductOrderId}
              currentUser={this.state.currentUser}
              onCustomerEdit={this.onCustomerEdit}
            />
            <OrderStatus
              display={this.state.isOrderStatusPageVisible}
              onClose={this.toggleOrderStatusPage}
              cart={this.state.cart}
              onCountDecrement={this.onCountDecrement}
              onCountIncrement={this.onCountIncrement}
              deleteItem={this.deleteItem}
              isOrderSuccessful={true}
              orderConfirmationStatus={this.state.orderConfirmationStatus}
            />
          </>
        )}
      </div>
    );
  }
}

export default PrivateProducts;
