import React from "react";
import { Switch, Route } from "react-router-dom";
import Products from "../views/Products";
import Contact from "../views/Contact";
import Login from "../auth/Login";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Products} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/contact" component={Contact} />
    </Switch>
  );
};

export default Routes;
