import axios from "axios";

export const login = (user) => {
  return axios
    .post("https://lutianshowers.com/admin/api/company/login", {
      email: user.email,
      password: user.password,
    })
    .then((response) => {
      localStorage.setItem("usertoken", response.data.success.token);
      localStorage.setItem("tokenexpiry", response.data.success.expiration);
      return response.data.success;
    })
    .catch((err) => {
      console.log(err);
    });
};
