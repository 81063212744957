import React from "react";
import { stringLimit } from "../functions/StringLimit";
import Modal from "../components/Modal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Table } from "reactstrap";

class Checkout extends React.Component {
  state = {
    checkout: {},
    orderNeedsConfirmation: false,
    totalCheckoutItems: null,
    totalOrderLogistics: null,
    totalOrderPrice: null,
    totalOrderPriceWithLogistics: null,
    paymentMethods: [],
    isPrintForCustomerModalVisible: false,
    isDivToPrintVisible: false,
  };

  printRef = React.createRef();

  componentDidMount = () => {
    const paymentMethods = [
      {
        id: 0,
        title: "Bank",
        method: "bank",
        visibility: this.props.currentUser.bank,
        isChosen: false,
      },
      {
        id: 1,
        title: "Cash",
        method: "cash",
        visibility: this.props.currentUser.cash,
        isChosen: false,
      },
      {
        id: 2,
        title: "Western Union",
        method: "western_union",
        visibility: this.props.currentUser.western_union,
        isChosen: false,
      },
    ];

    this.setState({
      paymentMethods,
    });
  };

  onChange = (e) => {
    const paymentMethods = [...this.state.paymentMethods];
    const chosenMethod = paymentMethods.find(
      (item) => item.method === e.target.value
    );
    paymentMethods.forEach((item, index) => {
      if (index === chosenMethod.id) {
        item.isChosen = true;
      } else {
        item.isChosen = false;
      }
    });

    this.setState({
      paymentMethods,
    });
  };

  onDrop = (acceptedFiles) => {
    const errors = { ...this.state.errors };
    delete errors.img;
    const fileObj = acceptedFiles;
    this.setState({
      checkoutPhotos: fileObj,
    });
  };

  onOrderClick = (event) => {
    event.preventDefault();
    this.props.onAddProductOrderId();
    const orderNeedsConfirmationStatus = this.props.cart.find(
      (item) =>
        item.NewWidth < +item.MinWidth ||
        item.NewWidth > +item.MaxWidth ||
        item.NewLength < +item.MinLength ||
        item.NewLength > +item.MaxLength ||
        item.NewHeight < +item.MinHeight ||
        item.NewHeight > +item.MaxHeight
    );

    let orderNeedsConfirmation = false;

    if (orderNeedsConfirmationStatus) {
      orderNeedsConfirmation = true;
    } else {
      orderNeedsConfirmation = false;
    }

    this.props.checkOrderConfirmationStatus(orderNeedsConfirmation);
    this.props.toggleOrderStatusPage();

    const totalCheckoutItems = this.props.cart.reduce(
      (a, b) => +a + +b.cartCounter,
      0
    );

    const totalOrderLogistics = +this.props.logisticsPrice * totalCheckoutItems;

    const totalOrderPriceWithLogistics =
      Number(
        this.props.cart
          .reduce((a, b) => +a + +b.productTotalPrice * b.cartCounter, 0)
          .toFixed(2)
      ) + totalOrderLogistics;
    const totalOrderPrice = Number(
      this.props.cart
        .reduce((a, b) => +a + +b.productTotalPrice * b.cartCounter, 0)
        .toFixed(2)
    );

    this.setState(
      {
        totalCheckoutItems,
        totalOrderLogistics,
        totalOrderPriceWithLogistics,
        totalOrderPrice,
      },
      () => {
        let formData = new FormData();
        formData.append("userId", this.props.userId);
        formData.append("totalOrderPrice", this.state.totalOrderPrice);
        formData.append("totalOrderLogistics", this.state.totalOrderLogistics);
        formData.append("cart", JSON.stringify(this.props.cart));
        formData.append(
          "totalOrderPriceWithLogistics",
          this.state.totalOrderPriceWithLogistics
        );
        formData.append(
          "paymentMethods",
          JSON.stringify(this.state.paymentMethods)
        );
        formData.append("orderNeedsConfirmation", orderNeedsConfirmation);
        console.log("----", JSON.stringify(this.props.cart));
        fetch("https://lutianshowers.com/admin/api/sendreport", {
          // content-type header should not be specified!
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
          body: formData,
        })
          .then((response) => {
            console.log("Report sent", response);
          })
          .catch((error) => console.log(error));
      }
    );
  };

  togglePrintForCustomerModal = () => {
    this.setState({
      isPrintForCustomerModalVisible:
        !this.state.isPrintForCustomerModalVisible,
    });
  };

  printPdf = () => {
    const charts = this.printRef?.current;
    html2canvas(charts, { useCORS: true, allowTaint: true, scrollY: 0 }).then(
      (canvas) => {
        const image = { type: "jpeg", quality: 0.98 };
        const margin = [0.5, 0.5];
        const filename = "test.pdf";

        const imgWidth = 8.5;
        let pageHeight = 11;

        const innerPageWidth = imgWidth - margin[0] * 2;
        const innerPageHeight = pageHeight - margin[1] * 2;

        // Calculate the number of pages.
        const pxFullHeight = canvas.height;
        const pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
        const nPages = Math.ceil(pxFullHeight / pxPageHeight);
        debugger;

        // Define pageHeight separately so it can be trimmed on the final page.
        pageHeight = innerPageHeight;

        // Create a one-page canvas to split up the full image.
        const pageCanvas = document.createElement("canvas");
        const pageCtx = pageCanvas.getContext("2d");
        pageCanvas.width = canvas.width;
        pageCanvas.height = pxPageHeight;

        // Initialize the PDF.
        const pdf = new jsPDF("p", "in", [8.5, 11]);

        for (let page = 0; page < nPages; page += 1) {
          // Trim the final page to reduce file size.
          if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
            pageCanvas.height = pxFullHeight % pxPageHeight;
            pageHeight =
              (pageCanvas.height * innerPageWidth) / pageCanvas.width;
          }

          // Display the page.
          const w = pageCanvas.width;
          const h = pageCanvas.height;
          pageCtx.fillStyle = "white";
          pageCtx.fillRect(0, 0, w, h);
          pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

          // Add the page to the PDF.
          if (page > 0) pdf.addPage();
          const imgData = pageCanvas.toDataURL(
            `image/${image.type}`,
            image.quality
          );
          pdf.addImage(
            imgData,
            image.type,
            margin[1],
            margin[0],
            innerPageWidth,
            pageHeight
          );
        }

        pdf.save(filename);
        this.setState({
          isDivToPrintVisible: false,
        });
      }
    );
  };

  setPrintDivToVisible = () => {
    this.setState(
      {
        isDivToPrintVisible: true,
      },
      () => {
        this.printPdf();
      }
    );
  };

  render() {
    const oneItemTotalPrice =
      Number(
        this.props.cart
          .reduce((a, b) => +a + Number(b.productTotalPrice) * b.cartCounter, 0)
          .toFixed(2)
      ) + Number(this.props.logisticsPrice);

    const oneItemTotalPriceEdited =
      Number(
        this.props.cart
          .reduce(
            (a, b) => +a + Number(b.editedPriceForCustomer) * b.cartCounter,
            0
          )
          .toFixed(2)
      ) + Number(this.props.logisticsPrice);

    const multipleItemsTotalPrice =
      Number(
        this.props.cart
          .reduce((a, b) => +a + +b.productTotalPrice * b.cartCounter, 0)
          .toFixed(2)
      ) +
      (+this.props.logisticsPrice +
        (+this.props.logisticsPrice / 2) *
          (this.props.cart.reduce((a, b) => +a + +b.cartCounter, 0) - 1));

    const multipleItemsTotalPriceEdited =
      Number(
        this.props.cart
          .reduce((a, b) => +a + +b.editedPriceForCustomer * b.cartCounter, 0)
          .toFixed(2)
      ) +
      (+this.props.logisticsPrice +
        (+this.props.logisticsPrice / 2) *
          (this.props.cart.reduce((a, b) => +a + +b.cartCounter, 0) - 1));
    return (
      <>
        {!this.props.display && <> </>}
        {this.props.display && (
          <>
            <div className="shopping-cart">
              <i
                className="material-icons close-shopping-cart cursor-pointer"
                onClick={this.props.onClose}
              >
                close
              </i>
              <div className="shopping-cart-table-wrapper">
                <table
                  className="full-width"
                  cellSpacing="0"
                  cellPadding="0"
                  border="none"
                >
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Product Details</th>
                      <th>Count</th>
                      <th>Additional</th>
                      <th>Price</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.cart.length > 0 &&
                      this.props.cart.map((item) => (
                        <tr>
                          <td>
                            <img
                              src="https://i.ibb.co/S5kSFgD/Zana-Dekor.png"
                              alt="img"
                            />
                          </td>
                          <td>
                            <h6>{item.product_name}</h6>
                            {item.chosenVariations.length > 0 &&
                              item.chosenVariations.map((el) => (
                                <p>
                                  {el.type}: <span>{el.value}</span>
                                </p>
                              ))}
                            <p>
                              Size:{" "}
                              <span>
                                {item.NewWidth}cm x {item.NewLength}cm x{" "}
                                {item.NewHeight}cm
                              </span>
                            </p>
                          </td>
                          <td style={{ userSelect: "none" }}>
                            <i
                              className="cursor-pointer"
                              onClick={() => this.props.onCountDecrement(item)}
                            >
                              -
                            </i>
                            {item.cartCounter}
                            <i
                              className="cursor-pointer"
                              onClick={() => this.props.onCountIncrement(item)}
                            >
                              +
                            </i>
                          </td>
                          <td className="message-td">
                            <p>
                              Message:{" "}
                              {stringLimit(item.productOrderMessage, 20)}
                            </p>
                            <p>Images: {item.productOrderPhotos.length}</p>
                          </td>
                          <td>
                            {(
                              +item.productTotalPrice * item.cartCounter
                            ).toFixed(2)}
                            &euro;
                          </td>
                          <td>
                            <i
                              onClick={() => this.props.deleteItem(item)}
                              className="material-icons cursor-pointer"
                            >
                              delete
                            </i>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="shopping-cart-info">
                <div>
                  <div>
                    <h5>Checkout - Order Summary</h5>
                  </div>
                  <div>
                    <p>No. of Products: {this.props.cart.length}</p>
                    <p>
                      No. of Items:{" "}
                      {this.props.cart.reduce((a, b) => +a + +b.cartCounter, 0)}
                    </p>
                  </div>
                  <div>
                    <div>
                      <p>Payment Type: </p>
                      <div className="flex">
                        {this.state.paymentMethods.filter(
                          (item) => item.visibility === 1
                        ).length > 0 &&
                          this.state.paymentMethods.map((item) => (
                            <label
                              style={{
                                marginRight: "10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="radio"
                                name="paymentMethod"
                                value={item.method}
                                style={{ marginRight: "2px" }}
                                onChange={this.onChange}
                              />
                              {item.title}
                            </label>
                          ))}
                      </div>
                    </div>
                    <p>Delivery Address: Mati 1, Prishtine, 10000</p>
                  </div>
                  <div>
                    <div className="flex">
                      <p>Products Cost</p>
                      <p>
                        {this.props.cart
                          .reduce(
                            (a, b) => +a + +b.productTotalPrice * b.cartCounter,
                            0
                          )
                          .toFixed(2)}
                        &euro;
                      </p>
                    </div>
                    <div className="flex">
                      <p>Logistics Price</p>
                      <p>
                        {+this.props.logisticsPrice *
                          this.props.cart.reduce(
                            (a, b) => +a + +b.cartCounter,
                            0
                          )}
                        &euro;
                      </p>
                    </div>
                    <div className="flex">
                      <p>Total Order Price</p>
                      <p>
                        {" "}
                        {this.props.cart.reduce(
                          (a, b) => +a + +b.cartCounter,
                          0
                        ) === 1 && oneItemTotalPrice}
                        {this.props.cart.reduce(
                          (a, b) => +a + +b.cartCounter,
                          0
                        ) > 1 && multipleItemsTotalPrice}
                        &euro;
                      </p>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      <button onClick={this.togglePrintForCustomerModal}>
                        Edit Customer Prices
                      </button>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      <button onClick={this.onOrderClick}>Order</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="divToPrint"
              ref={this.printRef}
              style={{
                position: "absolute",
                width: "8.5in",
                minHeight: "11in",
                zIndex: "-1",
              }}
            >
              {this.state.isDivToPrintVisible && (
                <>
                  <div className="flex">
                    <h1>Lutian Showers</h1>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                  <div>
                    <h2>Invoice</h2>
                    <br />
                    <br />
                  </div>

                  <Table className="table-bordered table-striped full-width">
                    <thead>
                      <tr>
                        <th>
                          <h3>Item</h3>
                        </th>
                        <th>
                          <h3>Quantity</h3>
                        </th>
                        <th>
                          <h3>Unit Price</h3>
                        </th>
                        <th>
                          <h3>Price</h3>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.cart.length > 0 &&
                        this.props.cart.map((item, index) => (
                          <>
                            <tr>
                              <td>
                                <h3>{item.product_name}</h3>
                              </td>
                              <td>
                                <h3>{item.cartCounter}</h3>
                              </td>
                              <td>
                                <h3>{item.editedPriceForCustomer} &euro;</h3>
                              </td>
                              <td>
                                <h3>
                                  {" "}
                                  {+item.editedPriceForCustomer *
                                    item.cartCounter}{" "}
                                  &euro;
                                </h3>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h3>
                                  Size:{" "}
                                  <span>
                                    {item.NewWidth}cm x {item.NewLength}cm x{" "}
                                    {item.NewHeight}cm
                                  </span>
                                </h3>
                                {item.chosenVariations.length > 0 &&
                                  item.chosenVariations.map((el) => (
                                    <h3>
                                      {el.type}: <span>{el.value}</span>
                                    </h3>
                                  ))}
                              </td>
                              <td colSpan="3">
                                <h3>
                                  Checkout Message:{" "}
                                  {stringLimit(item.productOrderMessage, 20)}
                                </h3>
                              </td>
                            </tr>
                          </>
                        ))}
                      <tr>
                        <td colSpan="2" rowSpan="4" />
                        <td>
                          <h3>Subtotal: </h3>
                        </td>
                        <td>
                          <h3>
                            {this.props.cart
                              .reduce(
                                (a, b) =>
                                  +a +
                                  +b.editedPriceForCustomer * b.cartCounter,
                                0
                              )
                              .toFixed(2)}{" "}
                            &euro;
                          </h3>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3>Logistics</h3>
                        </td>
                        <td>
                          <h3>
                            {" "}
                            {+this.props.logisticsPrice *
                              this.props.cart.reduce(
                                (a, b) => +a + +b.cartCounter,
                                0
                              )}
                            &euro;
                          </h3>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3>Total Price</h3>
                        </td>
                        <td>
                          <h3>
                            {" "}
                            {this.props.cart.reduce(
                              (a, b) => +a + +b.cartCounter,
                              0
                            ) === 1 && oneItemTotalPriceEdited}
                            {this.props.cart.reduce(
                              (a, b) => +a + +b.cartCounter,
                              0
                            ) > 1 && multipleItemsTotalPriceEdited}
                          </h3>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )}
            </div>
          </>
        )}

        <Modal
          display={this.state.isPrintForCustomerModalVisible}
          title="Edit Customer Prices"
          className="additonal-modal added-successfully-modal"
          onClose={this.togglePrintForCustomerModal}
          onModalSubmit={this.setPrintDivToVisible}
        >
          <form style={{ textAlign: "left" }}>
            {this.props.cart.length > 0 &&
              this.props.cart.map((item) => (
                <div>
                  Product Name: {item.product_name}
                  <br />
                  Current Price: {item.productTotalPrice} &euro;
                  <br />
                  {item.chosenVariations.length > 0 &&
                    item.chosenVariations.map((el) => (
                      <>
                        {" "}
                        {el.type}: {el.value}
                        <br />
                      </>
                    ))}
                  Size: {item.NewWidth}cm x {item.NewLength}cm x{" "}
                  {item.NewHeight}cm
                  <label className="full-width">
                    Set New Price:{" "}
                    <input
                      type="number"
                      name={item.cartId}
                      data-id={item.cartId}
                      id={item.cartId}
                      className="productTotalPrice"
                      value={parseInt(item.editedPriceForCustomer, 10)}
                      onChange={(event) =>
                        this.props.onCustomerEdit(event.target)
                      }
                    />{" "}
                    &euro;
                  </label>
                  <br />
                  <br />
                </div>
              ))}
          </form>
        </Modal>
      </>
    );
  }
}

export default Checkout;
