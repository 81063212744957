import React from "react";
import Header from "../components/Header";

class MainLayout extends React.Component {
  state = {
    isExpanded: false,
  };

  render() {
    return (
      <div className="main-layout">
        <Header />

        <main
          className={`body-wrapper${
            this.state.isExpanded ? " expanded-menu" : ""
          }`}
        >
          {this.props.children}
        </main>
      </div>
    );
  }
}

export default MainLayout;
