import React from "react";

class Modal extends React.Component {

  wrapperRef = React.createRef();

  componentDidMount = () => {
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.props.display && this.wrapperRef && !this.wrapperRef.current.contains(event.target) && !this.props.isConfirmationModal) {
      this.props.onClose();
    }
  };

  render() {
    return (
      <>
        {this.props.display && (
          <div
            className={`modal__wrapper${
              this.props.className ? ` ${this.props.className}` : ""
            }`}
          >
            <div className="modal__inside" ref={this.wrapperRef}>
              <div className="modal__header">
                <h4>{this.props.title}</h4>
                {!this.props.noSubmitBtn && (
                  <i
                    className="material-icons close"
                    onClick={this.props.onClose}
                  >
                    close
                  </i>
                )}
              </div>
              <div className="modal__content">{this.props.children}</div>
              {!this.props.noSubmitBtn && (
                <div className="modal__submit">
                  {this.props.otherSubmitBtn && (
                    <button
                      type="button"
                      onClick={this.props.onSecondModalSubmit}
                    >
                      {this.props.otherSubmitBtn}
                    </button>
                  )}
                  <button type="button" onClick={this.props.onModalSubmit}>
                    {this.props.mainModalBtnText
                      ? this.props.mainModalBtnText
                      : "Save"}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Modal;
