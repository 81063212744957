import React from "react";
import { stringLimit } from "../functions/StringLimit";

const ShoppingCart = (props) => (
  <>
    {!props.display && <> </>}
    {props.display && (
      <div className="shopping-cart">
        <i
          className="material-icons close-shopping-cart cursor-pointer"
          onClick={props.onClose}
        >
          close
        </i>
        <div className="shopping-cart-table-wrapper">
          <table
            className="full-width"
            cellSpacing="0"
            cellPadding="0"
            border="none"
          >
            <thead>
              <tr>
                <th>Image</th>
                <th>Product Details</th>
                <th>Count</th>
                <th>Additional</th>
                <th>Price</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {props.cart.length > 0 &&
                props.cart.map((item) => (
                  <tr>
                    <td>
                      <img
                        src="https://i.ibb.co/S5kSFgD/Zana-Dekor.png"
                        alt="img"
                      />
                    </td>
                    <td>
                      <h6>{item.product_name}</h6>
                      {item.chosenVariations.length > 0 &&
                        item.chosenVariations.map((el) => (
                          <p>
                            {el.type}: <span>{el.value}</span>
                          </p>
                        ))}
                      <p>
                        Size:{" "}
                        <span>
                          {item.NewWidth}cm x {item.NewLength}cm x{" "}
                          {item.NewHeight}cm
                        </span>
                      </p>
                    </td>
                    <td>
                      <i
                        className="cursor-pointer"
                        onClick={() => props.onCountDecrement(item)}
                      >
                        -
                      </i>
                      {item.cartCounter}
                      <i
                        className="cursor-pointer"
                        onClick={() => props.onCountIncrement(item)}
                      >
                        +
                      </i>
                    </td>
                    <td className="message-td">
                      <p>
                        Message: {stringLimit(item.productOrderMessage, 20)}
                      </p>
                      <p>Images: {item.productOrderPhotos.length}</p>
                    </td>
                    <td></td>
                    <td>
                      {(+item.productTotalPrice * item.cartCounter).toFixed(2)}
                      &euro;
                    </td>
                    <td>
                      <i
                        onClick={() => props.deleteItem(item)}
                        className="material-icons cursor-pointer"
                      >
                        delete
                      </i>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="shopping-cart-info">
          <div>
            <div>
              <h5>Order Summary</h5>
            </div>
            <div>
              <p>No. of Products: {props.cart.length}</p>
              <p>
                No. of Items:{" "}
                {props.cart.reduce((a, b) => +a + +b.cartCounter, 0)}
              </p>
            </div>
            <div>
              <div className="flex">
                <p>Total Cost</p>
                <p>
                  {props.cart
                    .reduce(
                      (a, b) => +a + +b.productTotalPrice * b.cartCounter,
                      0
                    )
                    .toFixed(2)}
                  &euro;
                </p>
              </div>
              <div style={{ marginTop: "15px" }}>
                <button onClick={props.onCheckoutBtnClick}>Checkout</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
);

export default ShoppingCart;
