import React from "react";

const OrderStatus = (props) => (
  <>
    {!props.display && <> </>}
    {props.display && (
      <div className="shopping-cart order-status-modal">
        <i
          className="material-icons close-shopping-cart cursor-pointer"
          onClick={props.onClose}
        >
          close
        </i>

        <h5>
          {props.isOrderSuccessful &&
            !props.orderConfirmationStatus &&
            "Your order was successful"}
          {props.isOrderSuccessful &&
            props.orderConfirmationStatus &&
            "Your order needs confirmation due to dimensions of the products order are out of the default scope"}
          {!props.isOrderSuccessful &&
            "Your order failed to process, please try again or contact your administrator"}
        </h5>
        <div className="flex order-status-modal-btn-wrapper">
          {props.isOrderSuccessful && props.orderConfirmationStatus && (
            <>
              <button>Go Back</button>
            </>
          )}
          {!props.isOrderSuccessful && (
            <>
              <button>Try Again</button>
              <button>Support</button>
            </>
          )}
        </div>
      </div>
    )}
  </>
);

export default OrderStatus;
